@import "src/assets/scss/_consts.scss";

/*******************
/*Global
*******************/

.background_themecolor {
	background-color: $secondary-color !important;
 }

.closeModal {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	z-index: 9999;
}

.closeModal nb-icon {
	color: $primary-color;
	font-size: 2.25rem !important;
}

.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
}

.counter-down {
	background: $red-color;
	border: 2px black solid;
	font-size: 1.2rem;
	padding: 0.2rem;
}

.left-files {
  position: absolute;
  top: 5px;
  left: -200%;
	width: 100%;
	max-width: 360px;
	height: auto;
  background-color: $secondary-color;
  padding: 1.5rem 0 1.5rem 1.5rem;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	z-index: 999999999;
	box-shadow: 0px 0px 5px 0px #494949;
}

.show-files {
  left: 0;
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

.ngx-pagination .current {
	background: $primary-color !important;
}

/* Esta clase es solo para el componnente de IA, para controlar la altura del modal*/
.full-screen nb-card:not(.mb-0) {
	max-height: calc(100vh - 62px) !important;
}

@media (max-width: 578px)  {
	.full-screen nb-card:not(.mb-0) {
		height: 99% !important;
		max-height: 99% !important;
	}
}
/*===================================================*/

.CalendarStyle {
	display: flex;
  width: 300px;
}

.CalendarStyle .cal-month-view .cal-day-cell{
	min-height: 25px;
	height: 25px;
}

.CalendarStyle .cal-month-view .cal-cell-top {
	min-height: 25px;
	display: flex;
	justify-content: space-around;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.CalendarStyle .cal-month-view .cal-day-badge {
	position: absolute;
  top: 2px;
  left: 2px;
	margin: 0;
  padding: 2px 5px;
  font-size: 10px;
}

.CalendarStyle .cal-month-view .cal-day-number {
	font-size: 12px;
  margin: 5px 0 0 0;
}

.CalendarStyle .cal-month-view .cal-day-cell.cal-today .cal-day-number {
	font-size: 1.2em;
}

.CalendarStyle .cal-month-view .cal-header {
	font-size: 12px;
}

.CalendarStyle .cal-month-view .cal-header .cal-cell {
	text-transform: capitalize;
}

/*******************
/*Icons
*******************/

nb-icon:hover {
	color: $primary-color !important;
}

/*******************
/*fallback
*******************/
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/material-icons.woff2") format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

/*******************
/*Form
*******************/

.form-control, .form-control::placeholder {
	background-color: #ffffff !important;
}

.form-control:focus, .form-control:hover {
	background-color: #ffffff !important;
	border-color: $primary-color !important;
}

.form-control[readonly] {
	border-color: #e4e9f2 !important;
	cursor: default;
}

.nb-theme-default nb-select.SelectForm.appearance-hero.status-basic .select-button {
	background-image: none;
	background-color: #ffffff;
	border: 1px solid #e4e9f2;
}

.nb-theme-default nb-select.SelectForm.appearance-hero.status-basic .select-button nb-icon {
	color: $primary-color;
}

.nb-theme-default nb-select.SelectForm.appearance-hero.status-basic .select-button.placeholder {
	font-weight: 400;
}

.nb-theme-default nb-select.SelectForm.appearance-hero.status-basic .select-button:focus, .nb-theme-default nb-select.SelectForm.appearance-hero.status-basic .select-button:hover {
	background-color: #ffffff;
	background-image: none;
	border-color: $primary-color;
}

.nb-theme-default nb-option.SelectFormOpt, .nb-theme-default nb-option.SelectFormOpt:hover {
	color: $form-color;
	font-weight: 400 ;
}

.nb-theme-default nb-option.SelectFormOpt.selected, .nb-theme-default nb-option.SelectFormOpt:hover.selected {
	background-color: $primary-color !important;
}

.nb-theme-default nb-checkbox.status-basic .custom-checkbox.checked {
	background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.nb-theme-default nb-checkbox.status-basic .text {
	color: $form-color !important;
	font-weight: 400 !important;
}

.nb-theme-default nb-checkbox.status-basic .custom-checkbox:hover {
	border-color: $primary-color !important;
}

.error-message {
	color: $red-color !important;
	font-size: 12px;
}

.fullWidth-select {
	max-width: 100% !important;
	width: 100%;
}

/*toggle style*/
.item-profile .toggle-label {
	margin: 0;
}

.item-profile .toggle-label div {
	height: 1rem !important;
	width: 2rem !important;
}

.item-profile .toggle-label div span {
	width: 0.9rem !important;
	height: 0.9rem !important;
}

.item-profile .toggle-label div.toggle.checked span {
	left: calc(100% - 0.9rem - 1px - 1px) !important;
}

.item-profile .toggle-label div span nb-icon {
	width: 0.6rem !important;
}

/*******************
/*Style text
*******************/
.text-color-primary {
	color: $text-color-primary !important;
}

.text-color-secondary {
	color: $text-color-secondary !important;
}

.text-danger {
	color: $red-color;
}

/*******************
/*Buttons
*******************/

.btn-themecolor {
  background: $primary-color !important;
  border-color: $primary-color !important;
	color: #ffffff !important;
}

.btn-red {
	background-color: $red-color !important;
  border-color: $red-color !important;
	color: #ffffff !important;
}

.btn-red:hover, .btn-themecolor:hover {
	opacity: 0.7;
}

/*******************
/*Login, registrer & password
*******************/

.bright-green-color {
	color: $bright-green-color !important;
}

.link-color {
	color: $link-color !important;
}

.form-control-login, .form-control-login::placeholder {
	background-color: #F6F6F6 !important;
	color: $form-color !important;
	font-weight: 400 !important;
}

.form-control-login:focus {
	background-color: #F6F6F6 !important;
	border-color: $bright-green-color !important;
}

.button-primary {
	background-color: $green-color !important;
	border-color: $green-color !important;
	color: #fff !important;
}

.button-primary:hover {
	border-color: $green-color !important;
	background-color: #F6F6F6 !important;
	color: $text-color-primary !important;
}

.nb-theme-default nb-select.SelectGlobal.appearance-hero.status-basic .select-button {
	background-image: none;
	background-color: #F6F6F6;
	border: 1px solid #F6F6F6;
	color: $form-color;
	font-weight: 400;
}

.nb-theme-default nb-select.SelectGlobal.appearance-hero.status-basic .select-button nb-icon {
	color: $bright-green-color;
}

.nb-theme-default nb-select.SelectGlobal.appearance-hero.status-basic .select-button.placeholder {
	color: $form-color;
	font-weight: 400;
}

.nb-theme-default nb-select.SelectGlobal.appearance-hero.status-basic .select-button:focus {
	background-color: #F6F6F6 ;
	border-color: $bright-green-color;
}

.nb-theme-default nb-option.SelectGlobalOption, .nb-theme-default nb-option.SelectGlobalOption:hover {
	color: $form-color ;
	font-weight: 400 ;
}

.nb-theme-default nb-option.SelectGlobalOption.selected, .nb-theme-default nb-option.SelectGlobalOption:hover.selected {
	background-color: $bright-green-color !important;
}

.nb-theme-default nb-checkbox.checkbox-login.status-basic .custom-checkbox.checked {
	background-color: $bright-green-color !important;
  border-color: $bright-green-color !important;
}

.nb-theme-default nb-checkbox.checkbox-login.status-basic .custom-checkbox:hover {
	border-color: $bright-green-color !important;
}

.closeModal .close-global {
	color: $bright-green-color;
}

.closeModal .close-global:hover {
	color: $bright-green-color !important;
}

/*******************
/*CHAT BOT
*******************/

@media (max-width: 840px) {
	#chatbase-bubble-button {
		right: 1rem !important;
		z-index: 60 !important;
		bottom: 8rem !important;
	}
}

@media (max-width: 600px) {
	#chatbase-bubble-button {
		bottom: 6rem !important;
	}
}
/*******************
/*MODAL
*******************/

.modal-xl {
    max-width: 90% !important;
}

.modal-header {
    background: #dedede;
    padding: 5px 10px;
}

.modal-dialog-100 .modal-dialog {
    max-width: 100%;
    margin: 0px;
}

.modal-dialog-100 .modal-body {
    padding: 0;
}

.modal-dialog-100 .modal-dialog {
    height: 100%;
}

.modal-dialog-100-nuevo .modal-dialog .modal-content {
    height: 100%;
}

.modal-dialog-100 .modal-dialog .modal-content .component-host-scrollable {
    height: 100%;
    overflow: hidden!important;
}

.modal-dialog-95 .modal-dialog {
    max-width: 95%;
}

.modal-dialog-95{
	&.h-100{
		.modal-dialog{
			height: calc(100% - 60px)!important;
			.modal-content{
				height: 100%;
				.component-host-scrollable{
					height: 100%;
					// overflow: scroll!important;
					// &::-webkit-scrollbar {
					// 	width: 12px;
					// }

					// &::-webkit-scrollbar-track {
					// 	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
					// }

					// &::-webkit-scrollbar-thumb {
					// 	background-color: #598bff;
					// 	outline: 1px solid #054aeb;
					// 	border-radius: 100px;
					// 	border: 6px solid rgba(0, 0, 0, 0.2);
					// }
				}
			}
		}
	}
}

.modal-dialog-80{
	&.h-100{
		.modal-dialog{
			height: calc(100% - 20px)!important;
			.modal-content{
				height: 80vh;
				.component-host-scrollable{
					height: 100%;
					// overflow: scroll!important;
					.container, .bg-autor{
						height: 100%;
					}
				}
			}
		}
	}
}

.modal-dialog-90{
	&.h-100{
		.modal-dialog{
			height: calc(100% - 60px)!important;

			@media (max-width: 578px) {
        height: calc(97% - 0px) !important
      }
			.modal-content{
				height: 100%;
				.component-host-scrollable{
					height: 100%;
					// overflow: scroll!important;
					.container{
						height: 100%;
					}
				}
			}
		}
	}
}

.modal-dialog-100{
	&.h-100{
		.modal-dialog{
			height: 100%!important;
			.modal-content{
				height: 100%;
				.component-host-scrollable{
					height: 100%;
					// overflow: scroll!important;
					.container{
						height: 100%;
					}
				}
			}
		}
	}
}

.component-host-scrollable {
    overflow: inherit !important;
}

.modal-dialog-90 .modal-dialog {
    max-width: 90%;
}

@media (max-width: 578px) {
	.modal-dialog-90 .modal-dialog {
    max-width: 100% !important;
	}
}

.modal-dialog-95 .modal-dialog {
	max-width: 95%;
}

@media (max-width: 578px) {
	.modal-dialog-95 .modal-dialog {
		max-width: 100% !important;
	}
}

.modal-dialog-85 .modal-dialog {
    max-width: 85%;
}

.modal-dialog-80 .modal-dialog {
    max-width: 80%;
}

@media (max-width: 578px) {
	.modal-dialog-80 .modal-dialog {
    max-width: 100% !important;
	}
}

.modal-dialog-75 .modal-dialog {
    max-width: 75%;
}

.modal-dialog-70 .modal-dialog {
    max-width: 70%;
}

.modal-dialog-65 .modal-dialog {
    max-width: 65%;
}

@media (max-width: 578px) {
	.modal-dialog-65 .modal-dialog {
    max-width: 100% !important;
	}
}

.modal-dialog-60 .modal-dialog {
    max-width: 60%;
}

@media (max-width: 578px) {
	.modal-dialog-60 .modal-dialog {
    max-width: 100% !important;
	}
}

.modal-dialog-55 .modal-dialog {
    max-width: 55%;
}

.modal-dialog-50 .modal-dialog {
    max-width: 50%;
    padding-top: 30px;
    margin: 30px auto;
}

.modal-dialog-40 .modal-dialog {
	max-width: 60%;
	margin: 30px auto;
}

@media (max-width: 1415px) {
	.modal-dialog-40 .modal-dialog {
    max-width: 80% !important;
	}
}

@media (max-width: 578px) {
	.modal-dialog-40 .modal-dialog {
    max-width: 100% !important;
		margin: 10px;
		height: calc(97% - 0px) !important;
	}
}

/*MODAL ACEPTAR & CANCELAR*/
.modal-dialog-30 .modal-dialog {
	height: 100vh !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 100% !important;
	background-color: rgba(0, 0, 0, 0.55) !important;
	margin: 0 !important;
	max-width: 100% !important;
}

@media (max-width: 578px)  {
	.modal-dialog-30 .modal-dialog {
		height: 100vh !important;
	}
}

/* END MODAL ACEPTAR & CANCELAR*/

.modal-height-100-30 {
	height: calc(100vh - 31px) !important;
}

.modal-height-100-62 {
	height: calc(100vh - 62px) !important;
}

@media (max-width: 578px)  {
	.modal-height-100-30, .modal-height-100-62 {
		height: calc(98vh - 0px) !important
	}
}

.modal-max-height-100-62 {
	max-height: calc(100vh - 62px) !important;
}

@media (max-width: 578px)  {
	.modal-max-height-100-62 {
		height: 99% !important;
		max-height: 99% !important;
	}
}

/*MODAL MENU PRINCIPAL*/

.modal-max-height-100-65 {
	max-height: calc(100vh - 62px) !important;
}

@media (max-width: 578px)  {
	.modal-max-height-100-65 {
		max-height: calc(100vh - 25px) !important;
	}
}

.min-h-100 {
	min-height: 100%;
}

// MODAL PARA LOS TUTORIALES
.modal-dialog-35 .modal-dialog {
	max-width: 15%;
	margin: 30px auto;
	position: absolute;
  left: 15px;
  top: 50%;
  z-index: 99999999999999;
  transform: translate(0, -50%) !important;
	box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.25);
}

// FIN MODAL

.pointer {
	cursor: pointer;
}

.span-text-center {
	display: inline-block;
	width: 100%;
	text-align: center;
}


.sticky-top-header {
    z-index: 1040;
}

// TEXTOS
.textoFontSize07 {
    font-size: 0.7rem;
}

.textoFontSize08 {
    font-size: 0.8rem;
}

.textoFontSize09 {
    font-size: 0.9rem;
}

.textoFontSize1 {
    font-size: 1rem;
}

.textoFontSize11 {
    font-size: 1.1rem;
}

.textoFontSize12 {
    font-size: 1.2rem;
}

.colorTextoActivado {
    color: #224d98 !important;
}

.colorTextoDesactivado {
    color: #989898;
}

.colorTextoRojo {
    color: #ff0000;
}

.textoCajasEditarCurso {
    top: -10px;
    position: relative;
    font-weight: bold;
}

// FIN TEXTOS
.w60Percent {
    width: 60%;
}

.w70Percent {
    width: 70%;
}

.w80Percent {
    width: 80%;
}

.w90Percent {
    width: 90%;
}

.w100Percent {
    width: 100%;
}

.error {
    color: #ff0000;
}

.opcionesCurso {
    padding: 5px;
    /* border: 2px solid; */
    -webkit-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.botonCursoPrincipal {
    cursor: pointer;
    background-color: #ffffff;
    color: #224d98;
    font-weight: bold;
    font-size: 0.9rem;
    width: 100%;
    border: 0px;
    padding: 10px;
    -webkit-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.botonCursoPrincipal:hover {
    background-color: #dedede !important;
}

.botonCursoSecundario {
    cursor: pointer;
    background-color: #dedede;
    color: #224d98;
    font-weight: bold;
    font-size: 0.9rem;
    width: 100%;
    border: 0px;
    padding: 10px;
    -webkit-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.botonCursoSecundario:hover {
    background-color: #989898;
    color: #ffffff;
}

.botonLoginEntrar {
    cursor: pointer;
    background-color: #1fb5fa;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.9rem;
    width: 100%;
    border: 0px;
    padding: 10px;
    -webkit-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.botonLoginRegistrar {
    cursor: pointer;
    background-color: #d2e8f2;
    color: #000000;
    font-weight: bold;
    font-size: 0.9rem;
    width: 100%;
    border: 0px;
    padding: 10px;
    -webkit-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.iconoCursoPrincipal {
    cursor: pointer;
    background-color: #ffffff;
    color: #224d98;
    width: 100%;
    border: 0px;
    padding: 10px;
    -webkit-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.iconoCursoPrincipal:hover {
    background-color: #dedede;
}

.lenguajePrincipal {
    border: 3px solid #ff0000;
}

.lenguajeSecundario {
    border: 3px solid #ffe600;
}

.fondoLogin {
    background-color: rgba(244, 244, 244, 0.75);
}

// EDITAR CURSO
.fondoEditarCurso {
    background-color: #f4f4f4;
}
// EDITAR CURSO
.fondoBuscarCurso {
    background: linear-gradient(345deg, #C8D6DF, #A9C7DE);
}

// EDITAR CURSO
.fondoProfesor  {
    background: linear-gradient(345deg,#F8F2AC,#E4C65F);
}

.fondoBlanco {
    background-color: #ffffff;
}

.star-rating {
    top: -10px;
    left: 10px;
    position: relative;
    font-size: 1.5rem;
}

.star-rating-big {
    top: -10px;
    left: 10px;
    position: relative;
    font-size: 2rem;
}

.star-rating-small {
    top: -10px;
    left: 10px;
    position: relative;
    font-size: 1rem;
}

.borde-rojo {
    border: 2px solid #ff0000;
}

.borde-rojo-hover:hover {
    border: 1px solid #ff0000;
}

.borde-negro {
    border: 1px solid #000000;
}

.borde-caja-no-superior {
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
}

.borde-superior {
    border-top: 1px solid #000000;
}

.borde-izquierda {
    border-left: 1px solid #000000;
}

.borde-derecha {
    border-right: 1px solid #000000;
}

.borde-inferior {
    border-bottom: 1px solid #000000;
}

.item-seleccionado:hover {
    border: 2px solid #000832;
}

// EDITAR CURSO - GRAFOS
.caja-negra {
    background-color: #000000;
    color: #ffffff;
}

.caja-amarilla {
    background-color: #fffd38;
    color: #000000;
}

.caja-azul {
    background-color: #2c73b9;
    color: #ffffff;
}

.caja-blanca {
    background-color: #ffffff;
}

.caja-gris {
    background-color: #ebebeb;
}

.caja-gris-oscuro {
    background-color: #dedede;
}

.caja-blanca-height {
    height: 450px;
    overflow-y: visible;
}

.caja-blanca-small-height {
    height: 250px;
    overflow-y: visible;
}

.pestana-activa {
    background-color: #67757c;
    color: #ffffff !important;
    border-top: 1px solid #67757c;
    border-left: 1px solid #67757c;
    border-right: 1px solid #67757c;
    height: 35px;
    cursor: pointer;
}

.pestana-activa:hover {
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
}

.pestana-no-activa {
    background-color: #224d98;
    color: #ffffff;
    border-radius: 15px 15px 0px 0px;
    -moz-border-radius: 15px 15px 0px 0px;
    -webkit-border-radius: 15px 15px 0px 0px;
    height: 35px;
    cursor: pointer;
}

.pestana-no-activa:hover {
    border-top: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
}

.opcion-activa {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    height: 35px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}

.opcion-no-activa {
    background-color: #224d98;
    color: #ffffff;
    height: 35px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}

.opcion-no-activa:hover {
    border: 1px solid #ffffff;
}

// FIN EDITAR CURSO - GRAFOS
// FIN EDITAR CURSO
@media (min-width: 1600px) {
    .d-xg-block {
        display: block !important;
    }
    .d-xg-none {
        display: none !important;
    }
}

// Material Design
.full-width {
    width: 100%;
}

.material-icons.mat-icon-no-color {
    color: #224d98;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    font-size: 0.9rem;
}

.mat-form-field:not(.mat-form-field-appearance-standard) {
    .mat-form-field-infix {
        border-top: 0.75em solid transparent;
    }
}

.break-line {
    white-space: pre-wrap;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 99999 !important;
}

button:focus {
    outline: none !important;
    outline: none !important;
}

button {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.snackSuccess {
    color: white !important;
    font-weight: 600;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}

.snackError {
    color: white !important;
    font-weight: 600;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}
.snackInfo {
    color: white !important;
    font-weight: 600;
    background-color: #2186ae;
    background-color: #2186ae;
}
.ql-container {
    height: auto;
}

.icon-head {
    color: rgb(29, 73, 153);
}

.icon-tittle {
    color: white;
}

.button-salware {
    width: 40px;
    height: 40px;
}

.tittle {
    background-color: #2186ae;
    color: #ffffff;
}

.well {
    overflow-y: auto;
    max-height: 80vh;
    min-height: 20px;
    padding: 19px;
    //margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.w-fit {
    width: fit-content;
}

.flex {
    display: flex !important;
}

.d1 {
    display: flex !important;
    align-items: center;

    .modal-dialog {
        width: 96vw;
        height: 96vh;
        max-width: 96vw !important;
        margin: auto;
        padding: 0;
    }

    .modal-content {
        height: auto;
        width: 100%;
        min-height: 100%;
        border-radius: 0;

        .modal-body {
            min-height: 96vh;
            height: 96vh;
            max-height: 96vh;
            padding: 0 !important;
        }
    }
}

.bg-black {
    background-color: #000;
}

.mat-form-field-appearance-standard .mat-form-field-prefix,
.mat-form-field-appearance-standard .mat-form-field-suffix {
    place-self: center;
}

.mat-light-blue {
    background-color: #d2e8f2;
}
.mat-blue {
    background-color: #0ca6e4;
    color: white;
}

.salware-dialog {
    &.ok {
        .mat-dialog-container {
            background: #ffffff;
						padding: 2rem 3rem;
            .title-info {
                font-size: larger;
                font-weight: 800;
                color: #111113;
                padding: 15px;
            }
            .button-ok{
                font-weight: 500;
                margin: 10px;
            }
        }
    }

    &.nok {
        .mat-dialog-container {
            border-top: 8px solid $red-color;
        }
    }
}

.dialog-45 {
    justify-content: center;

    .mat-dialog-container {
        width: 45% !important;
        max-width: 45% !important;
        min-width: 45% !important;
    }
}

.mat-loading .mat-button-wrapper {
    visibility: hidden;
}

.mat-loading .mat-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    background-color: transparent;
}

.mat-icon {
    user-select: none;
}

.black-tooltip {
    font-size: 1.25em;
    background-color: #000;
}

.mat-button-toggle-group-appearance-standard, .mat-button-toggle-standalone.mat-button-toggle-appearance-standard{
    border-radius: 0!important;
}

.btnDelete{
    border-radius: 10px;
    background: linear-gradient(134deg, #BC0000, #FF5B62);
    box-shadow: 4px 4px 6px #414d5a,
    -3px -3px 6px #cff5ff;
    border: 3px solid #A9C6DE;
    color: white;
    padding: 0.5em 1.3em 0.5em 1.3em;
    height: 3em;
}

.btnDelete-roleEditor{
    background: linear-gradient(134deg, #BC0000, #FF5B62);
    color: white;
    border-radius: 8px;
    border: 3px solid #d0c3a9;
    box-shadow: 4px 4px 3px #4b453c, -3px -3px 6px #fffeef;
    height: 3em;
    padding: 0.5em 1em 0.5em 1em;
}

.btnGeneric{
    border-radius: 10px;
    background: linear-gradient(137deg, #CBE9F8, #F9FDFE);
    box-shadow: 4px 4px 6px #414d5a,
    -3px -3px 6px #cff5ff;
    border: 3px solid #A9C6DE;
    padding: 0.5em 1.3em 0.5em 1.3em;
    height: 3em;
    &>button{
        background-color: transparent;
        border: none;
    }
}

.btnGeneric-roleEditor {
    border-radius: 8px;
    border: 3px solid #d0c3a9;
    box-shadow: 4px 4px 3px #4b453c, -3px -3px 6px #fffeef;
    background: linear-gradient(137deg, #fffeef, #f8f6e6);
    height: 3em;
    padding: 0.5em 1em 0.5em 1em;
    cursor: pointer;
}

.btnSave{
    border-radius: 10px;
    background: linear-gradient(137deg, #00A3E5, #5AC4FD);
    box-shadow: 4px 4px 6px #414d5a,
    -3px -3px 6px #cff5ff;
    border: 3px solid #A9C6DE;
    padding: 0.5em 1.3em 0.5em 1.3em;
    height: 3em;
}

.btnDarkGreen{
    border-radius: 10px;
    background: linear-gradient(137deg, #009F53, #05CC6A);
    box-shadow: 4px 4px 6px #414d5a,
    -3px -3px 6px #cff5ff;
    border: 3px solid #A9C6DE;
    padding: 0.5em 1.3em 0.5em 1.3em;
    height: 3em;
    color: white;
}

.btnClearGreen{
    border-radius: 10px;
    background: linear-gradient(137deg, #B7FAD3, #DDFDF3);
    box-shadow: 4px 4px 6px #414d5a,
    -3px -3px 6px #cff5ff;
    border: 3px solid #A9C6DE;
    padding: 0.5em 1.3em 0.5em 1.3em;
    height: 3em;
}

.btnPlayGreen{
    border-radius: 10px;
    background: linear-gradient(137deg, #55D965, #8cfa94);
    box-shadow: 4px 4px 6px #414d5a,
    -3px -3px 6px #cff5ff;
    border: 3px solid #A9C6DE;
    padding: 0.5em 1.3em 0.5em 1.3em;
    height: 3em;
    color: #00622D;
}

.btnPlayGreen-cardTarget{
    border-radius: 10px;
    background: linear-gradient(137deg, #55D965, #8cfa94);
    padding: 0.5em 1.3em 0.5em 1.3em;
    height: 3em;
    color: #00622D;
    box-shadow: 3px 3px 3px #414d5a, -3px -3px 3px #cff5ff;
    border: 3px solid #e6e6e6;
}

.btnDarkBlue-cardTarget{
    border-radius: 10px;
    background: linear-gradient(137deg, #0057BB, #008BFA);
    box-shadow: 3px 3px 3px #414d5a, -3px -3px 3px #cff5ff;
    border: 3px solid #e6e6e6;
    padding: 0.5em 1.3em 0.5em 1.3em;
    height: 3em;
    color: white;
}

.btnDarkBlue{
    border-radius: 10px;
    background: linear-gradient(137deg, #0057BB, #008BFA);
    box-shadow: 4px 4px 6px #414d5a,
    -3px -3px 6px #cff5ff;
    border: 3px solid #A9C6DE;
    padding: 0.5em 1.3em 0.5em 1.3em;
    height: 3em;
    color: white;
}

.btnClearBlue{
    border-radius: 10px;
    background: linear-gradient(137deg, #00A4E9, #41C0FD);
    box-shadow: 4px 4px 6px #414d5a,
    -3px -3px 6px #cff5ff;
    border: 3px solid #A9C6DE;
    padding: 0.5em 1.3em 0.5em 1.3em;
    height: 3em;
    color: white;
}

.inputGeneric{
	box-shadow: inset 1px 1px 2px black, inset -1px -1px 2px #ffffff;
    background: linear-gradient(138deg, #7E95AE, #99B5D3);
    color: white;
    border-radius: 8px;
    padding-left: 1em;
    border: none;
}

.inputGeneric-roleEditor{
	box-shadow: inset 1px 1px 2px black, inset -1px -1px 2px #ffffff;
    background: linear-gradient(138deg, #8b7f6c, #766c58);
    color: white;
    border-radius: 8px;
    padding-left: 1em;
    border: none;
}

.inputGeneric::placeholder, .inputGeneric-roleEditor::placeholder{
    color: white;
}

.selectGeneric{
    border-radius: 8px;
    border: 3px solid #A9C6DE;
	box-shadow: 4px 4px 6px #414d5a, -3px -3px 6px #cff5ff;
    background: linear-gradient(137deg, #CBE9F8, #F9FDFE);
    width: auto;
    height: 3em;
    padding: 0.5em 1em 0.5em 1em;
}

.selectGeneric-roleEditor{
    border-radius: 8px;
    border: 3px solid #d0c3a9;
    box-shadow: 4px 4px 3px #4b453c, -3px -3px 6px #fffeef;
    background: linear-gradient(137deg, #fffeef, #f8f6e6);
    height: 3em;
    padding: 0.5em 1em 0.5em 1em;
}

.Select-form {
  position: relative !important;
	padding: 5px 16px !important;
	height: 2.67em !important;
	border-radius: 4px !important;
	background-color: #f7f9fc !important;
	border: 1px solid #598bff !important;
	color: #8f9bb3 !important;
	font-size: 0.9375rem !important;
	line-height: 1.6rem !important;
  cursor: pointer !important;
	width: 100%;
}

.Select-form select {
  width: 100% !important;
  padding: 10px !important;
  border: none !important;
  outline: none !important;
  background: transparent !important;
  appearance: none !important;
}

.Select-form::after {
  content: "\25BC";
  position: absolute !important;
  top: 50% !important;
  right: 10px !important;
  transform: translateY(-50%) !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important;
}

.Select-form:hover {
	background-color: #edf1f7 !important;
  border-color: #598bff !important;
}

.datePickerGeneric{
    .mat-form-field-flex{
			background-color: #f7f9fc !important;
			border-style: solid !important;
    	border-width: 1px !important;
			border-color: #e4e9f2 !important;
			color: #222b45 !important;
			padding-left: 1em !important;
			padding-right: 0 !important;
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			border-radius: 0.25rem !important;

    }

    .mat-input-element, .mat-form-field-label, .mat-form-field.mat-focused .mat-form-field-label, .mat-focused .mat-form-field-required-marker{
			color: #222b45 !important;
			font-size: .9375rem !important;
			font-weight: 600 !important;
    }

    .mat-form-field-underline{
        display: none;
    }

    .mat-datepicker-toggle-default-icon{
        fill: #222b45 !important;;
    }

}


.colorTextoActivadoCursoGrafo{
    font-size: xx-large;
    font-weight: bold;
    color: white !important;
}

.buttonPublicar{
    span{
        font-weight: 500;
        color: #052460;
    }
}

.text-button{
    color: #052460;
    font-weight: 500;
}

.divGrapos{
    box-shadow: inset 2px 2px 5px black, inset -2px -2px 5px #ffffff;
    background: linear-gradient(136deg, #F3F3F3, #FFFFFF);
    padding-left: 2em;
    padding-right: 2em;
}

.divCardGrafos{
    box-shadow: 2px 2px 5px black, -2px -2px 5px #ffffff;
}

.bg-estudiante{
    background: linear-gradient(134deg, #FBFBFB, #F5F5F5);
    // background: linear-gradient(310deg, #7e95ae, #99b5d3);
}
.bg-padre{
    background: linear-gradient(310deg, #6edea2, #afdec5);
}
.bg-profesor{
    background: linear-gradient(134deg, #FBFBFB, #F5F5F5);
    // background: linear-gradient(310deg, #e4c65f, #f8f2ac);
}
.bg-autor{
    background: linear-gradient(134deg, #FBFBFB, #F5F5F5);
    // background: linear-gradient(310deg, #d2bc9a, #efdfbf);
}
.bg-centro{
    background: linear-gradient(310deg, #f1d7d4, #f7ece3);
}
.bg-empleador{
    background: linear-gradient(310deg, #bfbfbf, #d9d9d9);
}


//////////////////////////////////////////
/// /////////Boton close*////////////////
.boton-close{
    font-size: 2rem;
    font-weight: normal;
    line-height: 0;
    color: #224d98 !important;
}
.boton-close:hover{
    color: #000;
    text-decoration: none;
    opacity: .75;
}

///////////////////////////////////
///////////////////////////////////





.animated {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .fast {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation-name: fadeIn;
  }

  .celebration-modal-backdrop, .celebration-modal-window{
    z-index: 1052!important;
		padding-top: 25vh;
  }

	.online-users{
		justify-content: center;
    align-items: center;
    display: flex;
    background: #3366ff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    font-weight: 600;
		height: 45px;
		.dot{
			height: 15px;
			width: 15px;
			background-color: #02D566;
			border-radius: 50%;
			margin-right: 5px;
		}
	}

	nb-layout.layout{
		min-height: 100% !important;
	}

	nb-card.no-edge-card {
		border: none !important;
		box-shadow: none !important;
}

.logo {
	width: auto;
	height: 200px;
}

@media (max-width: 768px) {
	.logo {
		height: 170px;
	}
}

.min-vh-99 {
	min-height: 99vh;
}

.max-w-900 {
	max-width: 900px;
}

.background_white {
	background-color: #ffffff !important;
}

.button_change_page button {
  box-shadow: 1px 1px 1px #08222e, -1px -1px 1px white;
}

.checked-page {
	box-shadow: 0 0 0 0.2rem rgb(74, 134, 232) !important;
}

.container-tree {
  border-radius: 5px;
}

@media (min-width: 1400px) {
	.col-xxl-1 {
    -ms-flex: 0 0 12.33%;
    flex: 0 0 12.33%;
    max-width: 12.33%;
	}
}

.logoLogin {
	height: 140px;
}

@media (max-height: 768px) {
	.logoLogin {
		height: 100px;
	}

	h1 {
		font-size: 2rem !important;
	}
}

.color-red-text {
	color: #ff3d71 !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

nb-toastr-container {
	margin-right: 60em !important;
}
